import React from 'react';
import './Courses.css'; // Ensure you create a corresponding CSS file for styles}



const Courses = () => {
    console.log('Courses Component Rendered'); // Add this line
    return (
        <div className="courses">
            <div className="tutors">
                <div className="background">
                    <div className="spanish class">
                    <img className="flag" src="/img/Spain.png" alt="Spanish Flag" />

                        <p className="name">Spanish</p>
                    </div>
                    <div className="english class">
                        <img className="flag" src="/img/England.png" alt="English Flag" />
                        <p className="name">English</p>
                    </div>
                    <div className="german class">
                        <img className="flag" src="/img/Germany.png" alt="German Flag" />
                        <p className="name">German</p>
                    </div>
                    <div className="italian class">
                        <img className="flag" src="/img/Italy.png" alt="Italian Flag" />
                        <p className="name">Italian</p>
                    </div>
                    <div className="french class">
                        <img className="flag" src="/img/France.png" alt="French Flag" />
                        <p className="name">French</p>
                    </div>
                    <div className="portuguese class">
                        <img className="flag" src="/img/Portugal.png" alt="Portuguese Flag" />
                        <p className="name">Portuguese</p>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Courses;
