import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="us">
            <div className="division"><img className="lineimage" src="/img/aboutus1.png" alt="Line" />Tutors<br></br><p className='division-text'>We’ve handpicked top-quality language tutors who you'll absolutely love working with.</p></div>
            <div className="division"><img className="lineimage" src="/img/aboutus2.png" alt="Line" />Verified<br></br><p className='division-text'>Our tutors are ID-verified, ensuring a safe and trustworthy language learning experience.</p></div>
            <div className="division"><img className="lineimage" src="/img/aboutus3.png" alt="Line" />Flexibility<br></br><p className='division-text'>Your tutor will tailor their approach to your language needs. Choose from our curated list of exceptional tutors.</p></div>
            <div className="division"><img className="lineimage" src="/img/aboutus4.png" alt="Line" />Tailored<br></br><p className='division-text'>Each tutor will create a personalized study plan specifically designed to meet your unique language learning goals.</p></div>

        </div>
    );
};

export default AboutUs;
