import React from "react";
import { Navigate } from "react-router-dom"; // Import Navigate
import { useAuth } from "../../contexts/authContext";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const { currentUser, role } = useAuth(); // Make sure you're getting `role` from the context


  // If the user is not logged in
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user is logged in but doesn't have the required role
  if (!allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" />;
  }

  // Render the component if all checks pass
  return element; // Ensure you're returning the `element` prop correctly
};

export default ProtectedRoute;
