import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'; 

const Footer = () => {
    return (
        <footer>
            <div className="content">
                <div className="copyright">© 2024 LearnLatte, All Rights Reserved</div>
                {/* Attribution */}
                <div className="attribution">
                    <a className="copyright" href="https://www.flaticon.com/free-icons/tutor" title="tutor icons">
                        About us icons created by Freepik - Flaticon
                    </a>
                </div>
                {/* Privacy Policy Link */}
                <div className="privacy-policy">
                    <Link to="/privacy" className="privacy-link">
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
