import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, updateProfile } from "firebase/auth";
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import "./Settings.css";
import moment from 'moment-timezone';

const Settings = () => {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  const [role, setRole] = useState('');
  const [newName, setNewName] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const [country, setCountry] = useState('');
  const [profileTitle, setProfileTitle] = useState('');
  const [profileDescription, setProfileDescription] = useState('');
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);


  // Tutor contact fields
  const [googleMeet, setGoogleMeet] = useState('');
  const [whereby, setWhereby] = useState('');
  const [skype, setSkype] = useState('');
  const [teams, setTeams] = useState('');
  const [zoom, setZoom] = useState(''); // Corrected variable name for Zoom

  const [selectedTimezone, setSelectedTimezone] = useState('');
  const timezones = moment.tz.names(); // Full list of timezones

  const CLIENT_ID = '665754372289-pgdo6p0g2f1g0gr0p75empn3353ua0vq.apps.googleusercontent.com';
  const CLIENT_SECRET = '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';
  const REDIRECT_URI = 'http://localhost:3000/settings';
  const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events';

  // Function to redirect to Google's OAuth 2.0 URL to get the auth code
  const getAuthCode = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `scope=${encodeURIComponent(SCOPES)}&` +
      `access_type=offline&` +
      `include_granted_scopes=true&` +
      `response_type=code&` +
      `client_id=${CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;

    window.location.href = authUrl;
  };

  // Function to exchange auth code for refresh token
  // Function to exchange auth code for refresh token
  const getRefreshToken = async () => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', new URLSearchParams({
        code: authCode,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: REDIRECT_URI,
        grant_type: 'authorization_code',
      }));

      const { refresh_token } = response.data;
      setRefreshToken(refresh_token);
      console.log('Refresh Token:', refresh_token);

      // Save the refresh token in Firestore under users/userUID as REFRESH_TOKEN
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          REFRESH_TOKEN: refresh_token
        }, { merge: true });
        console.log('Refresh token saved to Firestore successfully');
      }
    } catch (error) {
      console.error('Error getting refresh token:', error);
    }
  };


  // Function to handle getting the auth code from the URL when user is redirected back
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setAuthCode(code);
    }
  }, []);

  // Fetch role from Firestore
  const fetchUserRole = async () => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        setRole(userDoc.data().role);
        console.log('User Role:', userDoc.data().role);

        // If the user is a tutor, fetch their contact links
        if (userDoc.data().role === 'tutor') {
          setGoogleMeet(userDoc.data().googleMeet || '');
          setWhereby(userDoc.data().whereby || '');
          setSkype(userDoc.data().skype || '');
          setTeams(userDoc.data().teams || '');
          setZoom(userDoc.data().zoom || '');
        }
      } else {
        console.log('No such user document!');
      }
    }
  };

  useEffect(() => {
    if (user) {
      console.log('User UID:', user.uid);
      console.log('User Name:', user.displayName);

      // Fetch the role from Firestore if it exists
      fetchUserRole();
    }
  }, [user]);

  // Function to handle username update
  const handleUsernameChange = async () => {
    if (user) {
      try {
        // Update the displayName in Firebase Authentication
        await updateProfile(user, {
          displayName: newName
        });
        console.log('Username updated successfully:', newName);

        // Optionally update in Firestore
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, { name: newName }, { merge: true });
        console.log('Firestore document updated with new name');

      } catch (error) {
        console.error('Error updating username:', error);
      }
    }
  };

  // Function to handle contact links update for tutors
  const handleContactLinksUpdate = async () => {
    if (user) {
      try {
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          googleMeet,
          whereby,
          skype,
          teams,
          zoom,
        }, { merge: true });
        console.log('Contact links updated successfully');
      } catch (error) {
        console.error('Error updating contact links:', error);
      }
    }
  };

  const handleTimezoneChange = async (event) => {
    const newTimezone = event.target.value;
    setSelectedTimezone(newTimezone); // Update the state with the selected timezone

    // Automatically save the new timezone to Firestore
    if (user) {
      try {
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          timezone: newTimezone // Save the selected timezone
        }, { merge: true });
        console.log('Timezone updated successfully');
      } catch (error) {
        console.error('Error updating timezone:', error);
      }
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleLevelsChange = (event) => {
    const value = event.target.value;
    setSelectedLevels(prevLevels =>
      prevLevels.includes(value)
        ? prevLevels.filter(level => level !== value) // Remove if already selected
        : [...prevLevels, value] // Add if not selected
    );
  };

  const handleAgesChange = (event) => {
    const value = event.target.value;
    setSelectedAges(prevAges =>
      prevAges.includes(value)
        ? prevAges.filter(age => age !== value) // Remove if already selected
        : [...prevAges, value] // Add if not selected
    );
  };

  const handleProfileUpdate = async () => {
    if (user) {
      try {
        const userRef = doc(db, "users", user.uid);
        await setDoc(userRef, {
          country,
          profileTitle,
          profileDescription,
          teachingLevels: selectedLevels,
          teachingAges: selectedAges,
        }, { merge: true });
        console.log('Profile updated successfully');
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  };



  return (
    <div>
      <div className="settings">
        <button onClick={getAuthCode}>Connect your calendar </button>

        {authCode && (
          <button onClick={getRefreshToken}>Get Refresh Token saved to Firebase </button>
        )}

        <div>
          <p>Auth Code: {authCode}</p>
          <p>Refresh Token: {refreshToken}</p>
          <p>User Name: {user?.displayName}</p>
          <p>User UID: {user?.uid}</p>
          <p>User Role: {role}</p>
        </div>

        {/* Input to change username */}
        <div>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new username"
          />
          <button onClick={handleUsernameChange}>Change Username</button>
        </div>

        {/* Show contact link fields only if the user is a tutor */}
        {role === 'tutor' && (
          <div>
            <h3>Update Contact Links</h3>
            <input
              type="text"
              value={googleMeet}
              onChange={(e) => setGoogleMeet(e.target.value)}
              placeholder="Google Meet Link"
            />
            <input
              type="text"
              value={whereby}
              onChange={(e) => setWhereby(e.target.value)}
              placeholder="Whereby Link"
            />
            <input
              type="text"
              value={skype}
              onChange={(e) => setSkype(e.target.value)}
              placeholder="Skype ID"
            />
            <input
              type="text"
              value={teams}
              onChange={(e) => setTeams(e.target.value)}
              placeholder="Teams Link"
            />
            <input
              type="text"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              placeholder="Zoom Link"
            />
            <button onClick={handleContactLinksUpdate}>Update Contact Links</button>
          </div>
        )}
        <div>
          <label>Select Timezone:</label>
          <select value={selectedTimezone} onChange={handleTimezoneChange}>
            <option value="" disabled>Select a timezone</option>
            {timezones.map((timezone) => (
              <option key={timezone} value={timezone}>
                {timezone}
              </option>
            ))}
          </select>

        </div>
        <div className="input-group">
          {/* Country Selection */}
          <label>Country:</label>
          <select value={country} onChange={handleCountryChange} className="country-select">
            <option value="" disabled>Select your country</option>
            <option value="USA">United States</option>
            <option value="UK">United Kingdom</option>
            <option value="Canada">Canada</option>
            {/* Add more countries */}
          </select>
        </div>

        <div className="input-group">
          {/* Profile Title */}
          <label>Profile Title:</label>
          <input
            type="text"
            value={profileTitle}
            onChange={(e) => setProfileTitle(e.target.value)}
            placeholder="Enter profile title"
          />
        </div>

        <div className="input-group">
          {/* Profile Description */}
          <label>Profile Description:</label>
          <textarea
            value={profileDescription}
            onChange={(e) => setProfileDescription(e.target.value)}
            placeholder="Enter profile description"
          />
        </div>

        <div className="checkbox-group">
          {/* Teaching Levels */}
          <label>Teaching Levels:</label>
          {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map(level => (
            <label key={level}>
              <input
                type="checkbox"
                value={level}
                checked={selectedLevels.includes(level)}
                onChange={handleLevelsChange}
              />
              {level}
            </label>
          ))}
        </div>

        <div className="checkbox-group">
          {/* Teaching Ages */}
          <label>Teaching Ages:</label>
          {['Kids', 'Teenagers', 'Adults'].map(ageGroup => (
            <label key={ageGroup}>
              <input
                type="checkbox"
                value={ageGroup}
                checked={selectedAges.includes(ageGroup)}
                onChange={handleAgesChange}
              />
              {ageGroup}
            </label>
          ))}
        </div>

        {/* <button {onClick={handleProfileUpdate}}>Save Profile</button>   COMMENTED  BUTTON */}
      </div>
    </div>
  );
};

export default Settings;
