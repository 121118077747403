import React, { useEffect, useState } from 'react';
import './Students.css';
import { getFirestore, doc, getDocs, query, collection, where, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { handleCancel } from '../utils';

const db = getFirestore();
const auth = getAuth();

const Students = () => {
    const [students, setStudents] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (auth.currentUser) {
                const tutorUID = auth.currentUser.uid;
                await fetchStudentsWithTutorAndSave(tutorUID);
                console.log("Tutor UID:", tutorUID);
            } else {
                setError("El usuario no está autenticado.");
            }
        };

        fetchData();
    }, []);

    const fetchStudentsWithTutorAndSave = async (tutorUID) => {
        const studentsQuery = query(collection(db, "users"), where("role", "==", "student"));

        try {
            const studentSnapshot = await getDocs(studentsQuery);
            const filteredStudents = [];

            console.log("Total de estudiantes encontrados:", studentSnapshot.size);

            const studentsWithTutors = studentSnapshot.docs.map(async (studentDoc) => {
                const studentData = studentDoc.data();
                const studentUID = studentDoc.id;

                console.log(`Verificando al estudiante: ${studentUID} - ${studentData.name}`);

                const tutorRef = collection(db, `users/${studentUID}/tutors`);
                const tutorSnapshot = await getDocs(tutorRef);
                const hasTutor = tutorSnapshot.docs.some(tutorDoc => tutorDoc.id === tutorUID);

                if (hasTutor) {
                    console.log(`El estudiante ${studentData.name} tiene al tutor ${tutorUID}`);

                    const creditsDocRef = doc(db, `users/${studentUID}/tutors/${tutorUID}`);
                    const creditsDoc = await getDoc(creditsDocRef);
                    const creditsData = creditsDoc.data();
                    const tutorCredits = creditsData?.credits || 0;

                    const scheduledClassesDocRef = doc(db, `users/${studentUID}/tutors/${tutorUID}`);
                    const scheduledClassesDoc = await getDoc(scheduledClassesDocRef);
                    const scheduledData = scheduledClassesDoc.data();
                    const scheduledArray = scheduledData?.scheduled || [];

                    const studentInfo = {
                        uid: studentUID,
                        name: studentData.name,
                        credits: tutorCredits,
                        scheduledClasses: scheduledArray,
                    };


                    filteredStudents.push(studentInfo);
                } else {
                    console.log(`El estudiante ${studentData.name} NO tiene al tutor ${tutorUID}`);
                }
            });

            await Promise.all(studentsWithTutors);
            setStudents(filteredStudents);
            console.log("Estudiantes con el tutor:", filteredStudents);
        } catch (error) {
            console.error("Error al obtener los estudiantes:", error.message);
            setError("Error fetching students: " + error.message);
        }
    };

    function formatDate(dateString, originalTimeZone) {
        const date = new Date(dateString);
        if (isNaN(date)) {
            return "Invalid Date";
        }

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const toggleClasses = (index) => {
        setStudents((prevStudents) =>
            prevStudents.map((student, i) =>
                i === index ? { ...student, showClasses: !student.showClasses } : student
            )
        );
    };


    const handleReschedule = (classItem, studentUID, classIndex) => {
        try {
            const tutorUID = auth.currentUser.uid;
            localStorage.setItem("studentUID", studentUID);
            localStorage.setItem("tutorUID", tutorUID);
            localStorage.setItem("classIndex", classIndex);
            localStorage.setItem("action", "reschedule");

            console.log("UID del estudiante guardado:", studentUID);
            console.log("UID del tutor guardado:", tutorUID);
            console.log("Número de mapa (índice de la clase) guardado:", classIndex);
            console.log("Acción guardada:", "reschedule");
            navigate('/calendar');

        } catch (error) {
            console.error("Error al guardar en local storage:", error);
        }
    };

    const handleCancelClick = async (studentUID, classItem, tutorUID, classIndex) => {
        try {
            // Primero, encontramos el estudiante actual a partir de la lista de estudiantes
            const currentStudent = students.find(student => student.uid === studentUID);
            if (!currentStudent) {
                throw new Error(`Student with UID ${studentUID} not found`);
            }

            // Intentamos actualizar los créditos en Firestore antes de modificar el estado local
            const creditsDocRef = doc(db, `users/${studentUID}/tutors/${tutorUID}`);

            // Si la actualización en Firestore fue exitosa, entonces cancelamos la clase localmente
            await handleCancel(studentUID, classItem, tutorUID);

            // Después de cancelar la clase, actualizamos el estado para eliminar la clase de la lista
            setStudents(prevStudents =>
                prevStudents.map(student => {
                    if (student.uid === studentUID) {
                        const updatedClasses = student.scheduledClasses.filter((_, index) => index !== classIndex);
                        return { ...student, scheduledClasses: updatedClasses };
                    }
                    return student;
                })
            );

            console.log(`Class canceled for student: ${studentUID}, tutor: ${tutorUID}`);

            // Actualizamos los créditos localmente también
            setStudents(prevStudents =>
                prevStudents.map(student => {
                    if (student.uid === studentUID) {
                        return { ...student, credits: currentStudent.credits + 1 }; // Incrementamos los créditos directamente
                    }
                    return student;
                })
            );

        } catch (error) {
            console.error("Error during class cancellation and updating credits:", error);
        }
    };

    return (
        <div className="students-container">
            {error && <p className="error-message">{error}</p>}
            <h2 className="students-title">Students</h2>
            <ul className="student-list">
                {students.map((student, index) => (
                    <li key={index} className="student-card">
                        <div className="student-header">
                            <img
                                src={"/img/defaultprofilepic.png"}
                                alt={`${student.name}'s profile`}
                                className="profile-pic-student"
                            />
                            <div className="student-info">
                                <div className="student-name">{student.name}</div>
                                <div className="student-credits">Credits: {student.credits}</div>
                            </div>
                            <div className="toggle-button">
                                <button
                                    onClick={() => toggleClasses(index)}
                                    className="toggle-arrow"
                                >
                                    {student.showClasses ? '▲' : '▼'}
                                </button>
                            </div>
                        </div>

                        {student.showClasses && (
                            <div className="classes-container">
                                <div className="scheduled-classes">
                                    <h3>Scheduled Classes:</h3>
                                    {student.scheduledClasses.length > 0 ? (
                                        student.scheduledClasses.map((classItem, classIndex) => (
                                            <div key={classIndex} className="class-item">
                                                <p>{formatDate(classItem.start, classItem.timezone)}</p>
                                                <p>${classItem.cost}</p>
                                                <div className="class-actions">
                                                    <button
                                                        className="reschedule-button"
                                                        onClick={() => handleReschedule(classItem, student.uid, classIndex)}
                                                    >
                                                        Reschedule
                                                    </button>

                                                    <button
                                                        className="cancel-button"
                                                        onClick={() => handleCancelClick(student.uid, classItem, auth.currentUser.uid, classIndex)} // Pass student UID, class item, tutor UID, and class index
                                                    >
                                                        Cancel
                                                    </button>

                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No scheduled classes</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Students;
