import React from 'react';
import './Unauthorized.css'; 

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <h1 className='unauthorized-title'>Oops! You are not authorized.</h1>
      <p className='unauthorized-description'>But check out how cool this ice cream is dancing!</p><br></br>
      <img
        src="https://content.codecademy.com/articles/github-pages-via-web-app/happy-ice-cream.gif"
        alt="Dancing Ice Cream"
        className="ice-cream-gif"
      />
    </div>
  );
};

export default Unauthorized;
