import {
    doc,
    getDoc,
    getFirestore,
    updateDoc
} from "firebase/firestore";

const db = getFirestore();

export const handleCancel = async (studentUID, classItem, tutorUID) => {
    console.log("1. Start class cancellation process");

    // Eliminar la clase del estudiante en Firestore
    const classRef = doc(db, "users", studentUID, "tutors", tutorUID);
    const classSnapshot = await getDoc(classRef);
    const classData = classSnapshot.data();
    const classIndex = classData.scheduledClasses.findIndex(item => item.start === classItem.start);
    console.log("2. Fetched class data from Firestore");

    if (classIndex === -1) {
        throw new Error('Class not found');
    }
    console.log("3. Class found in Firestore");

    const eventId = classData.scheduledClasses[classIndex].eventId; // Extraer el eventId

    // Remover la clase del estudiante en Firestore
    const updatedClasses = classData.scheduledClasses.filter((item) => item.start !== classItem.start);
    await updateDoc(classRef, { scheduledClasses: updatedClasses });
    console.log(`4. Class canceled for student ${studentUID} and tutor ${tutorUID}`);

    // Ahora también eliminar la clase del tutor en Firestore
    const tutorClassRef = doc(db, "users", tutorUID, "students", studentUID);
    const tutorClassSnapshot = await getDoc(tutorClassRef);
    const tutorClassData = tutorClassSnapshot.data();

    if (tutorClassData && tutorClassData.scheduledClasses) {
        const updatedTutorClasses = tutorClassData.scheduledClasses.filter(item => item.start !== classItem.start);
        await updateDoc(tutorClassRef, { scheduledClasses: updatedTutorClasses });
        console.log(`5. Class canceled from tutor's document for student ${studentUID}.`);
    } else {
        console.warn(`Tutor document for ${tutorUID} does not contain scheduled classes for student ${studentUID}.`);
    }

    // Fetch calendarId from the tutor's document
    const tutorRef = doc(db, "users", tutorUID);
    const tutorSnapshot = await getDoc(tutorRef);
    const tutorData = tutorSnapshot.data();
    const calendarId = tutorData.calendarIds[0]; // Get the first calendarId
    console.log("6. Fetched calendar ID from tutor's document");

    // Now delete the event from Google Calendar using the eventId and calendarId
    const accessToken = localStorage.getItem('google_access_token');
    try {
        const deleteResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        console.log("7. Sent delete request to Google Calendar");

        if (!deleteResponse.ok) {
            throw new Error('Failed to delete the event from Google Calendar');
        }
        console.log('8. Google Calendar event deleted successfully.');
    } catch (error) {
        console.error("Error deleting Google Calendar event:", error);
        // Continuar el proceso aunque falle la eliminación del evento en Google Calendar
    }

    // Restablecer el crédito del estudiante
    const tutorDocRef = doc(db, "users", studentUID, "tutors", tutorUID);
    const tutorDocSnap = await getDoc(tutorDocRef);
    console.log("9. Fetched student's tutor document from Firestore");

    if (tutorDocSnap.exists()) {
        const currentCredits = tutorDocSnap.data().credits || 0;
        await updateDoc(tutorDocRef, { credits: currentCredits + 1 });
        console.log(`10. Credit added back to student ${studentUID}.`);

        // Decrementar el número de clases del tutor
        const tutorMainDocRef = doc(db, "users", tutorUID);
        const tutorMainDocSnap = await getDoc(tutorMainDocRef);
        console.log("11. Fetched tutor's main document from Firestore");

        if (tutorMainDocSnap.exists()) {
            const currentNumberOfLessons = tutorMainDocSnap.data().numberOfLessons || 0;
            await updateDoc(tutorMainDocRef, {
                numberOfLessons: currentNumberOfLessons > 0 ? increment(-1) : 0 // Decrementa solo si es mayor que 0
            });
            console.log(`12. Decremented one lesson for tutor ${tutorUID}.`);
        } else {
            console.warn(`13. Main tutor document for ${tutorUID} does not exist.`);
        }

    } else {
        console.warn(`14. Tutor document for ${tutorUID} does not exist for student ${studentUID}`);
    }
};