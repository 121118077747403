import React, { useState } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth'
import './register.css' // Import the CSS file

const Register = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { userLoggedIn } = useAuth()

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isRegistering) {
            setIsRegistering(true)
            await doCreateUserWithEmailAndPassword(email, password)
        }
    }

    return (
        <>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}

            <main className="register-container">
                <div className="register-card">
                    <div className="register-title">
                        <h3>Join Now and Start Learning!</h3>
                    </div>

                    <form
                        onSubmit={onSubmit}
                        className="register-form"
                    >
                        <div>
                            <label className="register-label">
                                Email
                            </label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                className="register-input"
                            />
                        </div>

                        <div>
                            <label className="register-label">
                                Password
                            </label>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='new-password'
                                required
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)}
                                className="register-input"
                            />
                        </div>

                        <div>
                            <label className="register-label">
                                Confirm Password
                            </label>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='off'
                                required
                                value={confirmPassword} 
                                onChange={(e) => setconfirmPassword(e.target.value)}
                                className="register-input"
                            />
                        </div>

                        {errorMessage && (
                            <span className="register-error">{errorMessage}</span>
                        )}

                        <button
                            type="submit"
                            disabled={isRegistering}
                            className={`register-button ${isRegistering ? 'register-button-disabled' : 'register-button-active'}`}
                        >
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </button>

                        <div className="register-footer">
                            Already have an account? {' '}
                            <Link to={'/login'}>Continue</Link>
                        </div>
                    </form>
                </div>
            </main>
        </>
    )
}

export default Register
