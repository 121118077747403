// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import { getFirestore, collection, getDocs } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuFcQwLQaZeGV7R2FBrw2D611scW2wIxs",
  authDomain: "learningapp-78b2f.firebaseapp.com",
  databaseURL: "https://learningapp-78b2f-default-rtdb.firebaseio.com",
  projectId: "learningapp-78b2f",
  storageBucket: "learningapp-78b2f.appspot.com",
  messagingSenderId: "942404400010",
  appId: "1:942404400010:web:680a4fa156bef8faf30e73",
  measurementId: "G-9ZP5E8SNFY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const analytics = getAnalytics(app);
const db = getFirestore();


export { app, auth, analytics, db };