import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase/firebase"; // Import auth from your firebase.js
import { GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore"; // Firestore functions

const AuthContext = React.createContext();
const db = getFirestore(); // Initialize Firestore


export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null); // Track current user
  const [userLoggedIn, setUserLoggedIn] = useState(false); // Track if user is logged in
  const [isEmailUser, setIsEmailUser] = useState(false); // Track if the user logged in via email
  const [isGoogleUser, setIsGoogleUser] = useState(false); // Track if the user logged in via Google
  const [role, setRole] = useState(null); // Track user role (student, teacher, admin)
  const [loading, setLoading] = useState(true); // Track loading state for initial auth check

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser); // Subscribe to auth state changes
    return unsubscribe; // Cleanup the listener on unmount
  }, []);

  // Initialize user on auth state change
  async function initializeUser(user) {
    if (user) {
      setCurrentUser({ ...user }); // Set the current authenticated user

      // Check login method (email or Google)
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);

      const isGoogle = user.providerData.some(
        (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
      );
      setIsGoogleUser(isGoogle);

      // Handle user profile (check or create in Firestore)
      await handleUserProfile(user);

      setUserLoggedIn(true); // User is logged in
    } else {
      setCurrentUser(null); // Reset user
      setUserLoggedIn(false); // No user logged in
      setRole(null); // Reset role when user logs out
    }

    setLoading(false); // Auth check complete
  }

  // Handle the user profile in Firestore
  // Handle the user profile in Firestore
  async function handleUserProfile(user) {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setRole(userData.role); // Set role from Firestore
    } else {
      const newUserProfile = {
        uid: user.uid,
        name: user.displayName || "Unnamed User",
        email: user.email,
        role: "student" // default role
      };
      await setDoc(userRef, newUserProfile);
      setRole('student');
    }
  }



  // Auth context value
  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    role, // Include role in the context
    setCurrentUser, // Allow updating the user from components
  };


  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Only render children once loading is complete */}
    </AuthContext.Provider>
  );
}
