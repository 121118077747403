import React from 'react';
import './Privacy.css';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1 className="privacy-title">Privacy Policy</h1>

            <p className="privacy-effective-date"><strong>Effective Date</strong>: 16/Oct/24</p>

            <p className="privacy-text">
                <strong>LearningApp</strong> respects your privacy and is committed to protecting the personal information you provide to us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our app.
            </p>

            <h2 className="privacy-subtitle">1. Information We Collect</h2>
            <p className="privacy-text">We only collect the following information from you:</p>
            <ul className="privacy-list">
                <li className="privacy-list-item"><strong>Username</strong>: We collect your username to identify you within the app and personalize your experience.</li>
                <li className="privacy-list-item"><strong>Calendar Access</strong>: We request access to your Google Calendar to allow scheduling and managing of classes based on your availability.</li>
            </ul>

            <h2 className="privacy-subtitle">2. How We Use Your Information</h2>
            <p className="privacy-text">We use the collected information to:</p>
            <ul className="privacy-list">
                <li className="privacy-list-item">Provide and personalize the app's functionality.</li>
                <li className="privacy-list-item">Facilitate scheduling of classes, including viewing your availability and setting reminders.</li>
            </ul>

            <p className="privacy-text">We will not share or sell your information with third parties unless it is necessary to provide services (e.g., Google Calendar integration), required by law, or you have given explicit consent.</p>

            <h2 className="privacy-subtitle">3. Data Retention</h2>
            <p className="privacy-text">We retain your username and calendar access data as long as your account is active. If you choose to delete your account, all associated data will be permanently deleted.</p>

            <h2 className="privacy-subtitle">4. Data Security</h2>
            <p className="privacy-text">We take appropriate technical and organizational measures to ensure that your data is treated securely and protected from unauthorized access, disclosure, alteration, or destruction.</p>

            <h2 className="privacy-subtitle">5. Third-Party Services</h2>
            <p className="privacy-text">Our app integrates with the <strong>Google Calendar API</strong> to provide the requested scheduling features. Please review Google's privacy policy to understand how your calendar data is handled.</p>

            <h2 className="privacy-subtitle">6. Your Rights</h2>
            <p className="privacy-text">You have the right to:</p>
            <ul className="privacy-list">
                <li className="privacy-list-item">Request access to the personal data we hold about you.</li>
                <li className="privacy-list-item">Correct any inaccuracies in your data.</li>
                <li className="privacy-list-item">Withdraw consent for us to access your calendar at any time through your device settings.</li>
            </ul>

            <h2 className="privacy-subtitle">7. Changes to This Privacy Policy</h2>
            <p className="privacy-text">We may update this Privacy Policy from time to time. Any changes will be posted within the app, and the effective date will be updated.</p>

            <h2 className="privacy-subtitle">8. Contact Us</h2>
            <p className="privacy-text">If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at <strong>ambulansubique@gmail.com</strong>.</p>
        </div>
    );
};

export default Privacy;
