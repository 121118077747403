import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';

const Header = () => {
    // State to track if dark mode is enabled
    const [isDarkMode, setIsDarkMode] = useState(false);

    // State to track if the menu is open
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // State to track scroll for hiding nav
    const [hidden, setHidden] = useState(false);

    const darkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Effect to apply dark mode class to body
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add("dark-mode");
        } else {
            document.body.classList.remove("dark-mode");
        }
    }, [isDarkMode]);

    // Effect to handle scroll for hiding nav
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 50; // Adjust scroll threshold
            if (window.scrollY > scrollThreshold) {
                setHidden(true);  // Hide nav when scrollY > threshold
            } else {
                setHidden(false); // Show nav when scrollY <= threshold
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const { currentUser } = useAuth();
    const { userLoggedIn } = useAuth();
    const { role } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // Check if the current route is not the homepage
    const isNotHomePage = location.pathname !== '/';

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 50; // Adjust scroll threshold

            // Do not hide the header when the current route is '/calendar'
            if (location.pathname === '/calendar') {
                setHidden(false); // Always show the header on the /calendar route
                return;
            }

            if (window.scrollY > scrollThreshold) {
                setHidden(true);  // Hide nav when scrollY > threshold
            } else {
                setHidden(false); // Show nav when scrollY <= threshold
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location.pathname]); // Adding location.pathname as a dependency


    return (
        <header>
            <nav className={hidden ? 'hidden-nav' : ''}>
                <div className="text">
                    <Link className="header" to="/">
                        <h1
                            className="header"
                            style={{
                                color: isNotHomePage ? 'black' : 'white', // Black text on other routes, white text on the homepage
                            }}
                        >
                            LearnLantern
                        </h1>
                    </Link>

                    {/* Hamburger icon for small screens */}
                    {!isMenuOpen && (
                        <div className="menu-icon" onClick={toggleMenu}>
                            &#9776; {/* Hamburger icon */}
                        </div>
                    )}

                    {/* Main nav list (visible on large screens) */}
                    <ul className="line">
                        <Link to="/tutors" className="navbutton">
                            <li className="normal-li">Find Tutors</li>
                        </Link>
                        <Link to="/login" className="navbutton">
                            {!userLoggedIn && <li className="normal-li">Log In</li>}
                        </Link>

                        {userLoggedIn && (role === 'student' || role === 'admin') && (
                            <Link to="/tutorinfo" className="navbutton">
                                <li className="normal-li">My Lessons and Tutors</li>
                            </Link>
                        )}
                        {userLoggedIn && (role === 'tutor' || role === 'admin') && (
                            <Link to="/students" className="navbutton">
                                <li className="normal-li">My Students</li>
                            </Link>
                        )}
                        {userLoggedIn && (
                            <>
                                <li className="normal-li">Hello {currentUser.displayName ? currentUser.displayName : currentUser.email}.</li>
                                <li onClick={() => doSignOut().then(() => navigate('/'))} className="text-sm text-blue-600 underline normal-li">Logout</li>
                            </>
                        )}
                        {userLoggedIn && (role === 'tutor' || role === 'admin' || role === 'student') && (
                            <Link to="/settings" className="navbutton">
                                <li className="normal-li">Settings</li>
                            </Link>
                        )}
                    </ul>

                </div>
            </nav>

            {/* Sliding menu for small screens */}
            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                {/* Close button (X) */}
                <span className="menu-close" onClick={toggleMenu}>
                    &times; {/* X icon */}
                </span>
                <ul>
                    <Link to="/tutors" className="navbutton">
                        <li onClick={toggleMenu} className="menu-li">Find Tutors</li>
                    </Link>
                    <Link to="/login" className="navbutton">
                        {!userLoggedIn && <li onClick={toggleMenu} className="menu-li">Log In</li>}
                    </Link>

                    {userLoggedIn && (role === 'student' || role === 'admin') && (
                        <Link to="/tutorinfo" className="navbutton">
                            <li onClick={toggleMenu} className="menu-li">My Tutors</li>
                        </Link>
                    )}
                    {userLoggedIn && (role === 'tutor' || role === 'admin') && (
                        <Link to="/students" className="navbutton">
                            <li onClick={toggleMenu} className="menu-li">My Students</li>
                        </Link>
                    )}
                    {userLoggedIn && (
                        <>
                            <li className="menu-li">Hello {currentUser.displayName ? currentUser.displayName : currentUser.email}.</li>
                            <li onClick={() => {
                                doSignOut().then(() => {
                                    localStorage.clear(); // Clears all local storage data
                                    navigate('/');
                                });
                                toggleMenu();
                            }} className="text-sm text-blue-600 underline menu-li">
                                Logout
                            </li>

                        </>
                    )}
                </ul>
            </div>

        </header>
    );
};

export default Header;
